import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch all contacts
export const fetchAllContacts = createAsyncThunk(
  "contacts/fetchAll",
  async () => {
    const response = await axios.get("/api/contacts");
    return response.data;
  }
);

// Delete contact
export const deleteContact = createAsyncThunk("contacts/delete", async (id) => {
  await axios.delete(`/api/contacts/${id}`);
  return id;
});

// Update contact
export const updateContact = createAsyncThunk(
  "contacts/update",
  async ({ id, contactData }) => {
    const response = await axios.put(`/api/contacts/${id}`, contactData);
    return response.data;
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    name: "",
    phone: "",
    email: "",
    message: "",
    contacts: [],
    loading: false,
    error: null,
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload;
      })
      .addCase(fetchAllContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.contacts = state.contacts.filter(
          (contact) => contact._id !== action.payload
        );
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        const index = state.contacts.findIndex(
          (contact) => contact._id === action.payload._id
        );
        if (index !== -1) {
          state.contacts[index] = action.payload;
        }
      });
  },
});

export const { setName, setPhone, setEmail, setMessage } = contactSlice.actions;
export default contactSlice.reducer;
