import request from "../../utils/request";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Fetch all contacts
export const fetchAllContacts = createAsyncThunk(
  "contacts/fetchAll",
  async () => {
    const response = await request.get("/api/contacts");
    return response.data;
  }
);

// Delete contact
export const deleteContact = createAsyncThunk("contacts/delete", async (id) => {
  await request.delete(`/api/contacts/${id}`);
  return id;
});

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contacts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload;
      })
      .addCase(fetchAllContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.contacts = state.contacts.filter(
          (contact) => contact._id !== action.payload
        );
      });
  },
});

export default contactSlice.reducer;
