import g79 from "../images/icons/Group 79.png";
import g80 from "../images/icons/Group 80.png";
import g81 from "../images/icons/Group 81.png";
import e11 from "../images/icons/feature2.png";
import preparation from "../images/preparation.bmp";
import backend from "../images/backend.bmp";
import javascript from "../images/javascript.bmp";
import "../styles/midcontent.css";
import { Link } from "react-router-dom";

const MidContent = () => {
  return (
    <div className="mid-content-container">
      <div className="companies">
        <img src="/Company.png" alt="company logos" />
      </div>

      <div className="center-headded-section">
        <div className="cloud-top">
          <h2 className="cloud-software-title">
            <span>All-In-One</span> Cloud Software.
          </h2>
          <p className="cloud-software-description">
            Oon Tech is one powerful online software suite that combines all the
            tools needed to run a successful school or office.
          </p>
        </div>

        <div className="features">
          <div className="feature">
            <div className="icon">
              <img src={g81} alt="Billing Icon" width={100} height={100} />
            </div>
            <h3>Online Billing, Invoicing, & Contracts</h3>
            <p>
              Simple and secure control of your organization’s financial and
              legal transactions. Send customized invoices and contracts.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img src={g80} alt="Scheduling Icon" width={100} height={100} />
            </div>
            <h3>Oon Tech Education In Arabic, Spanish, and English</h3>
            <p>
              Schedule and reserve classrooms at one campus or multiple
              campuses. Keep detailed records of student attendance.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                src={g79}
                alt="Customer Tracking Icon"
                width={100}
                height={100}
              />
            </div>
            <h3>No More Language Barrier</h3>
            <p>Oon Tech Education in Arabic, Spanish, and English.</p>
          </div>
        </div>
      </div>

      <div className="center-headded-section">
        <div className="cloud-top">
          <h2 className="cloud-software-title">
            <span>What is</span> OonTech?
          </h2>
          <p className="cloud-software-description">
            Oon Tech is one powerful online software suite that combines all the
            tools needed to run a successful school or office.
          </p>
        </div>

        <div className="slides">
          <div className="slide-card card1">
            <h3>For Instructor</h3>
            <p>
              Oon Tech allows you to easily create, manage, and schedule
              classrooms across multiple campuses.
            </p>
          </div>
          <div className="slide-card card2">
            <h3>For Student</h3>
            <p>
              Oon Tech allows you to easily create, manage, and schedule
              classrooms across multiple campuses.
            </p>
          </div>
        </div>
      </div>

      <div className="classroom-section">
        <div className="classroom-content">
          <h2>
            <span className="highlight">
              Everything you can do in a physical classroom,
            </span>
            you can do with Oon Tech
          </h2>
          <p>
            Oon Tech’s school management software helps traditional and online
            schools manage scheduling, attendance, payments, and virtual
            classrooms all in one secure cloud-based system.
          </p>
          <a href="/about" className="learn-more">
            Learn more
          </a>
        </div>
        <div className="classroom-img-container">
          <div className="icon">
            <img
              src="/assets/classroom.png"
              alt="Classroom"
              width={500}
              height={300}
            />
          </div>
          <div className="play-button">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M8 5v14l11-7z" />
            </svg>
          </div>
        </div>
      </div>

      <div className="center-headded-section">
        <div className="cloud-top">
          <h2 className="cloud-software-title">
            <span>Our </span> Features
          </h2>
          <p className="cloud-software-description">
            This very extraordinary feature, can make learning activities more
            efficient
          </p>
        </div>

        <div className="features-">
          <div className="feature--card feature-1"></div>
          <div className="feature-2">
            <h3>
              A <span>user interface</span> designed for the classroom
            </h3>

            <div className="features-icons">
              <div className="feature-">
                <div className="feature-icon">
                  <img
                    src="/icons/feature1.png"
                    alt="Feature Icon 1"
                    width={60}
                    height={60}
                  />
                </div>
                <div className="feature-text">
                  <p>
                    Teachers don’t get lost in the grid view and have a
                    dedicated Podium space.
                  </p>
                </div>
              </div>
              <div className="feature-">
                <div className="feature-icon">
                  <img src={e11} alt="" />
                </div>
                <div className="feature-text">
                  <p>
                    TA’s and presenters can be moved to the front of the class.
                  </p>
                </div>
              </div>
              <div className="feature-">
                <div className="feature-icon">
                  <img
                    src="/icons/feature3.png"
                    alt="Feature Icon 3"
                    width={60}
                    height={60}
                  />
                </div>
                <div className="feature-text">
                  <p>
                    Teachers can easily see all students and class data at one
                    time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="center-headded-section">
        <div className="cloud-top">
          <h2 className="cloud-software-title">
            <span>All-In-One</span> Cloud Software.
          </h2>
          <p className="cloud-software-description">
            Oon Tech is one powerful online software suite that combines all the
            tools needed to run a successful school or office.
          </p>
        </div>

        <div className="features">
          <div className="feature">
            <div className="icon">
              <img src={preparation} alt="Billing Icon" />
            </div>
            <h3>Preparation for Software Education </h3>
            <p>
              Foundation Period : basics of algorithms & focusing on
              problem-solving and logical thinking Solid understanding of
              low-level programming Programing Programming (OOP)Data Structure
              Data Structures Algorithm Advanced Computer science
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img src={backend} alt="Scheduling Icon" />
            </div>
            <h3>Back End Development </h3>
            <p>
              Programming and SQL Level I and II T-SQL Additional SQL Education
              AWS Suits of Tool
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img src={javascript} alt="Customer Tracking Icon" />
            </div>
            <h3>Java Script </h3>
            <p>
              Java Preliminary Classes Java Script (React or Angular) Over 32
              Projects
            </p>
          </div>
        </div>
        <Link to={"/contact"}>
          <button className="subscribe-button">
            Register For Consultation{" "}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MidContent;
