import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Oon Tech is a leading provider of innovative in teaching software
        solutions specializing in Front-End Full Stack Development, computing
        and cybersecurity. With a robust portfolio of products, including
        enterprise-level cloud management platforms and advanced threat
        detection systems, Oon Tech empowers students to enhance their learning
        efficiency and seek to become the best software developer. Oon Tech is
        an academy platform that provides and integrates academic theories and
        rigorous hands- on learning. Serving a diverse clientele ranging from
        students with different backgrounds with distinguish language
        capabilities to advanced software engineers who desire to improve and
        learn new technologies. Fortune 500 companies, TechSol is renowned for
        its commitment to cutting-edge educational technology and exceptional
        customer service. By leveraging the latest advancements in machine
        learning and blockchain, Oon Tech Academy continues to set learning
        standards to adults and minors who desire programming at different
        levels.
      </p>
      <p>
        Oon Tech Academy prepares all-around software engineers, not just a
        webpage developer. Our pedagogy of wedge-driven science allows students
        to become self- directed pioneers in the software engineering market and
        capable of applying skills and knowledge effectively in their future
        workplace and quicker to learn a new technologies as it emerges. Oon
        Tech is the only bi-lingual software development school that offers the
        knowledge in multiple languages.
      </p>
      <h2>Overview</h2>
      <p>
        The Full-Time and Part-Time Software Engineering Immersive Programs
        equip you with full- stack JavaScript and computer science knowledge,
        preparing you for mid- to senior-level software engineering roles. The
        carefully crafted curriculum immerses you in modern web technologies,
        such as React and Node.js, while the supportive community motivates you
        to tackle unique and unfamiliar problems, ensuring you&#39;re ready for
        the constantly evolving tech landscape.
      </p>
      <p>
        All immersive programs cover a core curriculum based on Computer
        Science, front-end development, backend development, and modern
        technologies like machine learning. The distinctive educational approach
        helps you develop a deep and practical understanding through a
        combination of lectures, pair programming, workshops, project building,
        and more. By the end of the program, you will have created an impressive
        portfolio and demonstrated your ability to think and work like an
        engineer. Beyond the core curriculum, Oon Tech focuses on developing
        well-rounded engineers by emphasizing &#39;soft engineering skills&#39;
        and providing the tools necessary to become a mid/senior-level
        developer. It&#39;s not just about what you know—it&#39;s about how you
        solve problems and communicate your solutions.
      </p>
    </div>
  );
};

export default About;
