

import Hero from '../components/Hero';
import MidContent from '../components/MidContent';


const HomePage = () => {
  return (
    
        <div className="homepage-container">
      <div>
        <title>oonTech</title>
        <meta name="description" content="Welcome to oonTech" />
      </div>
      <main>
        <Hero />
        <MidContent/>
      </main>
   </div>
  );
}

export default HomePage