import React from 'react';
import './RoadMap.css';
import blue from "../images/th (1).jpg"
import yellow from "../images/th (2).jpg"
const RoadMap = () => {
  return (
    <div className="roadmap-container">
      <h1 className="roadmap-title">Road Map</h1>

      <section className="step-section">
        <h2>Step-by-Step Path</h2>
        <div className="phase">
          <h3 className="phase-title blue">First Phase (Blue)</h3>
          <p>Begin with Foundations Level 1 and progress through all Algorithms Levels, covering a comprehensive range from basic to advanced algorithmic concepts.</p>
          <img src={blue} alt="" />
        </div>

        <div className="phase">
          <h3 className="phase-title yellow">Second Phase (Yellow)</h3>
          <p>
            C++ All Levels, followed by Java at Beginner, Intermediate, and Advanced levels, and then explore Spring. Continue with Data Structures all levels, gaining broad programming and software development skills. 
            At this stage, the student is a Full Stack Desktop Developer, approximately 50% prepared for any specialization.
          </p>
          <img src={yellow} alt="" />
        </div>
      </section>

      <section className="next-steps-section">
        <h2>Next Steps</h2>
        <ul>
          <li>Delve into Competitive Programming (CP) and master Database.</li>
          <li>Challenge expertise with OOP through a full project, C++ & Database Integration, a Database Project, and advanced Data Structures.</li>
          <li>Explore JavaScript from Beginner to Advanced levels and deepen your knowledge with React/Angular.</li>
          <li>Achieve proficiency in Cloud Teaching, encompassing foundational to advanced web development and cloud-based instruction, including certifications:</li>
          <ul className="certifications">
            <li>Certified Cloud Practitioner</li>
            <li>Certified Solutions Architect</li>
            <li>Certified Developer – Associate</li>
            <li>Certified DevOps Engineer – Professional</li>
          </ul>
        </ul>
      </section>
    </div>
  );
};

export default RoadMap;
