import "../styles/Hero.css";
import heroImg from "../images/header-pic.png";
import { Link } from "react-router-dom";


const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="hero-left">
          <h1>
            <span className="highlight">Studying Online</span> is now much
            easier
          </h1>
          <p>
            OonTech is an interesting platform that will teach you in a more
            interactive way
          </p>
          <div className="cta-buttons">
            <Link className="navLink" to="/contact">
              <button className="join-btn">Join now </button>
            </Link>
            
          </div>
        </div>
        <div className="hero-right">
          <img className="hero-image" src={heroImg} alt="Student" />
          {/* <img
            src="/path/to/your/image.jpg"
            
            
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
