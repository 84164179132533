import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./form.css";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/apiCalls/authApiCall";
import LoadingSpinner from "../../components/isLoading/LoadingSpinner";
import google from "./google.png";
import { authActions } from "../../redux/slices/authSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      dispatch(authActions.login(JSON.parse(userInfo)));
    }

    const query = new URLSearchParams(location.search);
    const user = query.get("user");
    
    if (user) {
      const parsedUser = JSON.parse(decodeURIComponent(user));
      dispatch(authActions.login(parsedUser));
      localStorage.setItem("userInfo", JSON.stringify(parsedUser));
    }
    const qerror = query.get("error");
    if(qerror){
      
      const parsedError = (decodeURIComponent(qerror));
      toast.error(parsedError);
    }
  }, [dispatch, location.search]);

  const googleAuth = (e) => {
    e.preventDefault();
    window.open(`${process.env.REACT_APP_API_URL}/api/auth/google`, "_self");
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (email.trim() === "") return toast.error("Email is required");
    if (password.trim() === "") return toast.error("Password is required");

    setIsLoading(true);
    try {
      await dispatch(loginUser({ email, password }));
    } catch (error) {
      toast.error("Failed to login");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="form-container">
      <h1 className="form-title">Login</h1>
      <form onSubmit={formSubmitHandler} className="form">
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-input"
            id="email"
            placeholder="enter email here"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-input"
            id="password"
            placeholder="Password here"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="form-btn" type="submit" disabled={isLoading}>
          {isLoading ? <LoadingSpinner /> : "Login"}
        </button>
        <button className="google_btn" onClick={googleAuth} disabled={isLoading}>
        
        <span>Login Using Google</span><img src={google} alt="google icon" />
      </button>
      </form>
      
      <div className="form-footer">
        Forget password? <Link to="/forgot-password"></Link>
      </div>
    </section>
  );
};

export default Login;