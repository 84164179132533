import "../styles/Footer.css"; 
import logo from "../images/logo.png";

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="logo">
          <img
            src={logo}
            alt="OnTech Logo"
            width={150}
            height={40}
          />
          <span>Virtual Class for Zoom</span>
        </div>
        <div className="newsletter">
          <h3>Subscribe to get our Newsletter</h3>
          <form className="newsletter-form">
            <input type="email" placeholder="Your Email" className="input" />
            <button type="submit" className="subscribe-button">
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        
        <p>&copy; {year} OonTech Technologies Inc.</p>
      </div>
    </footer>
  );
};

export default Footer;
