import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./form.css";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/apiCalls/authApiCall";
import swal from "sweetalert";
import LoadingSpinner from "../../components/isLoading/LoadingSpinner";
import google from "./google.png";

const Register = () => {
  const dispatch = useDispatch();
  const { registerMessage } = useSelector((state) => state.auth);

  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const googleAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/auth/google/callback`,
      "_self"
    );
  };

  // Form Submit Handler
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (username.trim() === "") return toast.error("Username is required");
    if (email.trim() === "") return toast.error("Email is required");
    if (phone.trim() === "") return toast.error("Phone is required");
    if (password.trim() === "") return toast.error("Password is required");

    setIsLoading(true); // Start loading state
    try {
      await dispatch(registerUser({ username, email,phone, password }));
      setIsLoading(false); // Stop loading state on success
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
      console.error("Registration Error:", error.message);
      toast.error("Registration failed. Please try again.");
    }
  };

  const navigate = useNavigate();

  if (registerMessage) {
    swal({
      title: registerMessage,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        navigate("/login");
      }
    });
  }

  return (
    <section className="form-container">
      <h1 className="form-title">Sign Up</h1>
      <form onSubmit={formSubmitHandler} className="form">
        <div className="form-group">
          <label htmlFor="username" className="form-label">
            UserName
          </label>
          <input
            type="text"
            className="form-input"
            id="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Phone
          </label>
          <input
            type="phone"
            className="form-input"
            id="phone"
            placeholder="Phone here"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-input"
            id="email"
            placeholder="Email here"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-input"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="form-btn" type="submit" disabled={isLoading}>
          {isLoading ? <LoadingSpinner /> : "Craete Account"}
        </button>
        <button className="google_btn" onClick={googleAuth}>
          <span>Using Google </span>
          <img src={google} alt="google icon" />
        </button>
      </form>

      <div className="form-footer">
        You Have Account? <Link to="/login">Login</Link>
      </div>
    </section>
  );
};

export default Register;
