import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      {/* <h1 className="contact-us-title">Contact Us</h1> */}
      <p className="contact-us-paragraph">
        At OonTech, we warmly invite you to connect with us about our distinguished Language Programming Academy. Whether you're a business seeking to integrate advanced language solutions into your operations or an individual aspiring to elevate your programming skills, we are here to support you every step of the way.
      </p>
      <p className="contact-us-paragraph">
        Our team of seasoned professionals is committed to providing personalized guidance, state-of-the-art technology, and bespoke solutions tailored to your unique needs. We believe in cultivating meaningful relationships with our clients, ensuring that each interaction is both insightful and valuable.
      </p>

      <div className="contact-us-section">
        <h2 className="contact-us-heading">Algorithm and Hands-On Projects</h2>
        <p className="contact-us-paragraph">
          At OonTech, we take pride in delivering a programming education that transcends the ordinary. Our distinctive approach marries in-depth theoretical knowledge with practical application, ensuring that you not only grasp algorithms and coding principles but also master them through immersive hands-on projects.
        </p>
      </div>

      <div className="contact-us-section">
        <h2 className="contact-us-heading">Cloud Community</h2>
        <p className="contact-us-paragraph">
          Beyond our extensive programming education, OonTech offers specialized training in cloud and deployment services. You will gain hands-on experience with leading cloud platforms, learn best practices for deploying and scaling applications, and master efficient resource management.
        </p>
      </div>

      <div className="contact-info">
        <h3 className="contact-info-title">Email Address</h3>
        <p className="contact-info-email">
          <a href="mailto:info@oontech.com">info@oontech.com</a>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
