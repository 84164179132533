import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div className="form-container">
      <h1>Contact Us</h1>
      <ContactForm />
    </div>
  );
}
