import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import "../styles/Header.css";
import { useSelector } from "react-redux";

const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleBurgerClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img width={50} height={50} src={logo} alt="Logo" />
        </a>
      </div>
      
      {/* Burger icon for mobile */}
      <div className="nav-links-mobile" onClick={handleBurgerClick}>
        <div className={`burger-icon ${isMobileMenuOpen ? "open" : ""}`}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>

      <nav ref={menuRef} className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
        <Link className="navLink" to="/" onClick={handleBurgerClick}>
          Home
        </Link>
        <Link className="navLink" to="/contact" onClick={handleBurgerClick}>
          Contact
        </Link>
        <Link className="navLink" to="/roadmap" onClick={handleBurgerClick}>
          Road Map
        </Link>
        <Link className="navLink" to="/about"   onClick={handleBurgerClick}>
          About Us
        </Link>
      </nav>

      <button className="join"><Link to="/contact">Join now</Link></button>
    </header>
  );
};

export default Header;
